@font-face {
  font-family: 'BCSans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/BCSans/BCSans-Regular.woff2') format('woff2'),
    /* Optimized for very modern browsers */ url('../assets/fonts/BCSans/BCSans-Regular.woff') format('woff'),
    local('BCSans'); /* Modern Browsers */
  font-display: swap;
}

@font-face {
  font-family: 'BCSans';
  font-style: italic;
  src: url('../assets/fonts/BCSans/BCSans-Italic.woff2') format('woff2'),
    /* Optimized for very modern browsers */ url('../assets/fonts/BCSans/BCSans-Italic.woff') format('woff'),
    local('BCSans'); /* Modern Browsers */
  font-display: swap;
}

@font-face {
  font-family: 'BCSans';
  font-weight: 700;
  src: url('../assets/fonts/BCSans/BCSans-Bold.woff2') format('woff2'),
    /* Optimized for very modern browsers */ url('../assets/fonts/BCSans/BCSans-Bold.woff') format('woff'),
    local('BCSans'); /* Modern Browsers */
  font-display: swap;
}

@font-face {
  font-family: 'BCSans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/BCSans/BCSans-BoldItalic.woff2') format('woff2'),
    /* Optimized for very modern browsers */ url('../assets/fonts/BCSans/BCSans-BoldItalic.woff') format('woff'),
    local('BCSans'); /* Modern Browsers */
  font-display: swap;
}

$fontFamily: 'BCSans', Verdana, Arial, sans-serif;
